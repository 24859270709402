<!-- Componente hijo de ProductPage -->

<template>
    <!-- Puntos disponibles -->
    <q-card-section class="q-pt-none text-left q-pb-none">
        <p class="q-ma-xs">
            {{ $t("BuyingProcess.products.available-points-1") }}
            {{ settings.currency }}
            {{ $t("BuyingProcess.products.available-points-2") }}
            :
            <span class="text-weight-bold">{{ points.points.points }} {{ settings.currency_abbreviation }}</span>
        </p>
    </q-card-section>

    <div class="q-pa-md q-gutter-xs q-ma-md" style="border: 1px solid #f2f2f2; border-radius: 25px">
        <!-- Product options -->
        <div v-if="product.options != null">
          <div>
            <q-item v-for="attribute in productAttributes">
                <q-item-section>
                    <q-select
                      v-model="attribute.value" :label="attribute.label[$i18n.locale]" :options="attribute.options.map((option) => { return { label: option[$i18n.locale], value: option } })"
                      class="q-my-xs" style="min-width: 220px;" dense rounded outlined
                    />
                </q-item-section>
            </q-item>
          </div>
          
          <q-separator class="q-mt-lg q-mb-md" />
        </div>
        

        <!-- RECOGIDA EN TIENDA -->

        <!-- Título Recogida. Se activa tras haber escogido una opción-->
        <div v-show="titlePickUpStore">
            <div class="container-confirmation q-pt-sm q-pb-sm q-ml-sm q-mr-sm">
                <p class="text-left q-mb-xs q-ml-md text-weight-bold">
                    {{ $t("BuyingProcess.products.buy-confirmation") }}
                </p>
                <p class="text-left q-mt-xs q-ml-lg q-mr-md">
                    {{ $t("BuyingProcess.products.total-expense") }}: {{ product.value }}
                    {{ settings.currency_abbreviation }}
                </p>
                <p class="text-left q-mb-xs q-ml-md q-mb-md">
                    {{ $t("BuyingProcess.products.final-balance") }}:
                    {{ endingBalanceStore }}
                    {{ settings.currency_abbreviation }}
                </p>
            </div>

            <p class="text-left q-mb-xs q-ml-md q-mt-md" v-if="product.type !== 1">
                {{ $t("BuyingProcess.products.pickup-store") }}
            </p>
        </div>

        <div class="q-gutter-sm column">
          <!-- Radio Recogida en tienda -->
          <q-radio v-model="radioChosen" v-show="pickUpStore && product.type === 0" val="Recogida en tienda"
              :label="$t('BuyingProcess.products.pickup-store')" left-label class="justify-between cursor-pointer"
              @click="chooseStoreAddress()" />

          <!-- Opcion escogida en Recogida en Tienda que aparece abajo del Radio -->
          <q-item class="text-left justify-around" v-show="optionChosenStore">
              <q-item-section>
                  <q-item-label caption>{{ chosenOption.name }}
                  </q-item-label>
                  <q-item-label v-if="chosenOption">
                      {{ formatAddress(chosenOption.address.address, chosenOption.address.postal_code,
                      chosenOption.address.city, chosenOption.address.province, chosenOption.address.country,) }}
                  </q-item-label>
                  <q-item-label v-else class="q-pt-sm text-grey-7 text-overline text-weight-bold">
                      {{ $t("BuyingProcess.transactions.no-address-chosen") }}
                  </q-item-label>
              </q-item-section>

              <!-- Icono de edit. Activa el Dialog OptionStoreAddress a través de la funcíon showStoreAddress -->
              <q-item-section avatar v-model="editOption">
                  <q-icon color="primary" name="edit" class="cursor-pointer" @click="showStoreAddress()" />
              </q-item-section>
          </q-item>

          <!-- ENVÍO A DOMICILIO -->

          <!-- Añade coste extra del shipping -->
          <div v-if="settings.shipping && product.type !== 1" class="full-width justify-between">
              <!-- Título Envío a Domicilio. Se activa tras haber escogido una opción-->
              <div v-show="titleHomeDelivery">
                  <div class="container-confirmation q-pt-sm q-pb-sm q-ml-md q-mr-md">
                      <p class="text-left q-mb-xs q-ml-md text-weight-bold">
                          {{ $t("BuyingProcess.products.buy-confirmation") }}
                      </p>
                      <p class="text-left q-mt-xs q-ml-lg q-mr-md text-weight-light">
                          <!-- Confirmación de compra: <span class="text-weight-bold">{{ points.points.points }} {{ settings.currency_abbreviation }} </span> (saldo actual)  -->
                          {{ $t("BuyingProcess.products.total-expense") }}:
                          {{ product.value }} {{ settings.currency_abbreviation }} +
                          {{ settings.shipping_price }}
                          {{ settings.currency_abbreviation }}
                          <span style="text-transform: lowercase">({{ $t("BuyingProcess.products.home-delivery")
                              }})</span>
                          = {{ totalBalance }} {{ settings.currency_abbreviation }}
                      </p>
                      <p class="text-left q-mb-xs q-ml-md q-mb-md">
                          {{ $t("BuyingProcess.products.final-balance") }}:
                          {{ endingBalanceHomeDelivery }}
                          {{ settings.currency_abbreviation }}
                      </p>

                      <div class="text-left q-pl-md" v-if="endingBalanceHomeDelivery < 0">
                          <q-item-label caption class="text-red">
                              {{ $t("BuyingProcess.products.no-points") }}:
                          </q-item-label>
                      </div>
                  </div>

                  <p class="text-left q-mb-xs q-ml-md q-mt-md">
                      {{ $t("BuyingProcess.products.home-delivery") }}
                  </p>
              </div>

              <!-- Radio Envío a domicilio -->
              <q-radio v-model="radioChosen" v-show="homeDelivery" val="Envío a domicilio"
                  :label="$t('BuyingProcess.products.home-delivery')" left-label
                  class="full-width justify-between q-mt-lg q-pl-sm" @click="chooseHomeDeliveryAddress()"
                  id="HomeDelivery" />
          </div>

          <!-- Opcion escogida en Domicilio que aparece abajo del Radio -->
          <q-item class="text-left column" v-show="optionChosenHomeDelivery || product.type === 1">
              <div v-if="product.type !== 1">
                <div class="row justify-around">
                    <q-item-section>
                        <q-item-label caption>{{ $t("Profile.personal-address.shipping-address") }}
                        </q-item-label>
                        <q-item-label v-if="chosenShippingAddress">
                            {{ formatAddress(chosenShippingAddress.address, chosenShippingAddress.postal_code,
                            chosenShippingAddress.city, chosenShippingAddress.province, chosenShippingAddress.country,)
                            }}
                        </q-item-label>
                        <q-item-label v-else class="q-pt-sm text-grey-7 text-overline text-weight-bold">
                            {{ $t("BuyingProcess.transactions.no-address-chosen") }}
                        </q-item-label>
                    </q-item-section>
                    <!-- Icono de edit. Activa el Dialog OptionHomeDeliveryAddress a través de la funcíon showHomeDeliveryAddress -->
                    <q-item-section avatar v-model="editOption">
                        <q-icon color="primary" name="edit" class="cursor-pointer" @click="addressOptionsType='shipping';
                      addressOptions=shippingAddresses;
                      showHomeDeliveryAddress()" />
                    </q-item-section>
                </div>
                <div class="row justify-around q-mt-lg">
                    <q-item-section class="text-caption">
                        <q-checkbox v-model="sameAddressForInvoice"
                            :label="$t('BuyingProcess.transactions.use-same-address-invoice')" />
                    </q-item-section>
                </div>
              </div>

              <div class="row justify-around q-mt-md" v-if="sameAddressForInvoice===false || product.type === 1">
                  <q-item-section>
                      <q-item-label caption>{{ $t("Profile.personal-address.billing-address") }}
                      </q-item-label>
                      <q-item-label v-if="chosenBillingAddress">
                          {{ formatAddress(chosenBillingAddress.address, chosenBillingAddress.postal_code,
                          chosenBillingAddress.city, chosenBillingAddress.province, chosenBillingAddress.country,) }}
                      </q-item-label>
                      <q-item-label v-else class="q-pt-sm text-grey-7 text-overline text-weight-bold">
                          {{ $t("BuyingProcess.transactions.no-address-chosen") }}
                      </q-item-label>
                  </q-item-section>

                  <!-- Icono de edit. Activa el Dialog OptionHomeDeliveryAddress a través de la funcíon showHomeDeliveryAddress -->
                  <q-item-section avatar>
                      <q-icon color="primary" name="edit" class="cursor-pointer" @click="addressOptionsType='invoice';
                    addressOptions=billingAddresses;
                    showHomeDeliveryAddress()" />
                  </q-item-section>
              </div>

              <q-item-label v-if="product.type !== 1" caption class="text-left q-mt-xl">
                  {{ $t("BuyingProcess.products.extra-price") }}
                  <span class="text-weight-bold">{{ settings.shipping_price }} {{ settings.currency }}</span>
              </q-item-label>
          </q-item>
      </div>

      <q-separator class="q-mt-lg q-mb-md" />

      <!-- Note -->
      <div>
        <q-item>
            <q-item-section>
                <q-checkbox v-model="addNote" :label="$t('BuyingProcess.transactions.add-note')" />
            </q-item-section>
        </q-item>
        <q-item v-if="addNote">
            <q-item-section>
                <q-input class="note-input" filled v-model="NoteInput" :label="$t('BuyingProcess.transactions.note')"
                    type="textarea" autogrow maxlength="1000" />
            </q-item-section>
        </q-item>
      </div>
    </div>

    <!-- Botón de escoger opción -->
    <div class="container-button-like text-center q-mb-md q-pt-sm fixed-bottom">
        <ButtonOptions v-show="buttonBlueExchange" @click="chooseOption()">
            {{ $t("General.exchange") }}
        </ButtonOptions>
    </div>

    <!-- Enviar datos -->
    <div class="container-button-like text-center q-mb-md q-pb-lg fixed-bottom" id="buttonBuyProduct"
        style="z-index: -1">
        <ButtonOrange
            v-show="buttonOrangeExchange"
            @click="sendOrder()"
            :disabled="homeDelivery && endingBalanceHomeDelivery < 0"
        >
            {{ $t("General.buy") }}
            <q-spinner v-if="loading" size="20px" style="position: absolute; right: 34px" />
        </ButtonOrange>
    </div>

    <!-- Opcion Recogida en Tienda. Se activa este Dialog donde se puede escoger opciones de tienda  -->
    <!-- Recibe datos de OptionOwnAdress a través de emit -->

    <OptionStoreAddress v-model="dialogPickUpStoreOptions" @passOptionStore="displayStoreOption($event)"
        @storeSelected="(shop) => { chosenShippingAddress=shop.address; chosenBillingAddress=shop.address; }" />

    <!-- Opcion Envío a Domicilio. Se activa este Dialog donde se puede escoger dirección de Usuario o nueva dirección -->
    <!-- Recibe datos de OptionOwnAdress a través de emit -->

    <OptionHomeDeliveryAddress v-model="dialogHomeDeliveryOptions" :addresses="addressOptions"
        :addressType="addressOptionsType" @shippingAddressSelected="(address) => { chosenShippingAddress=address }"
        @billingAddressSelected="(address) => { chosenBillingAddress=address }"
        @addNewAddress="addAddressDialog=true" />

    <AddPersonalAddress v-model="addAddressDialog" :addressType="addressOptionsType" />

    <!-- Dialog último. Se activa cuando se realiza la transacción -->
    <div>
        <ChosenOptionDialog v-model="OrderDone" :product="product" />
    </div>

    <!-- Dialog por favor escoger opción -->
    <q-dialog v-model="alertPleaseChooseOption">
        <q-card :style="{ 'font-family': settings.typography }">
            <q-card-section>
                <div class="text-h6 text-center">
                    {{ $t("BuyingProcess.products.please-choose-option") }}
                </div>
            </q-card-section>
            <q-card-actions align="center">
                <q-btn class="btn-home" :label="$t('General.continue')"
                    :style="{ 'background-color': settings.color_primary }" v-close-popup />
            </q-card-actions>
        </q-card>
    </q-dialog>

    <!-- Dialog por favor especifique direccion -->
    <q-dialog v-model="alertPleaseChooseAddress">
        <q-card :style="{ 'font-family': settings.typography }">
            <q-card-section>
                <div class="text-h6 text-center">
                    {{ $t("BuyingProcess.products.please-choose-address") }}
                </div>
            </q-card-section>
            <q-card-actions align="center">
                <q-btn class="btn-home" :label="$t('General.continue')"
                    :style="{ 'background-color': settings.color_primary }" v-close-popup />
            </q-card-actions>
        </q-card>
    </q-dialog>

    <!-- Dialog loading order -->
    <q-dialog v-model="loadingOrder">
        <q-card :style="{ 'font-family': settings.typography }">
            <q-card-section class="text-center">
                <div class="text-h6 q-pb-md">
                    {{ $t("BuyingProcess.transactions.processing-order") }}
                </div>
                <q-spinner size="40px" />
            </q-card-section>
        </q-card>
    </q-dialog>

    <!-- Dialog pedido fallido -->
    <q-dialog v-model="orderFail" persistent>
        <q-card :style="{ 'font-family': settings.typography }">
            <q-card-actions align="center">
                <q-btn icon="close" color="red" size="25px" flat />
            </q-card-actions>
            <q-card-section>
                <div class="text-h6 text-center">
                    {{ $t("BuyingProcess.transactions.no-processing-order") }}
                </div>
            </q-card-section>
            <q-card-actions align="center">
                <q-btn class="btn-home" :label="$t('General.continue')"
                    :style="{ 'background-color': settings.color_primary }" v-close-popup />
            </q-card-actions>
            <q-card-actions align="center">
                <q-btn to="/" class="btn-home" :label="$t('BuyingProcess.transactions.go-back-home')"
                    :style="{ 'background-color': settings.color_accent }" v-close-popup />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { defineAsyncComponent } from "vue";
import OptionShowMixin from "../mixins/OptionShowMixin";
import settings from "@/mixins/settings";
import { formatAddress } from "@/services/helpers/AddressFormat";

const OptionStoreAddress = defineAsyncComponent(() =>
  import("./OptionStoreAddress.vue")
);

const OptionHomeDeliveryAddress = defineAsyncComponent(() =>
  import("./OptionHomeDeliveryAddress.vue")
);

const ButtonOrange = defineAsyncComponent(() =>
  import("../../shared/buttons/ButtonOrange.vue")
);

const ButtonOptions = defineAsyncComponent(() =>
  import("../../shared/buttons/ButtonOptions.vue")
);

const ChosenOptionDialog = defineAsyncComponent(() =>
  import("../components/ChosenOptionDialog.vue")
);

const AddPersonalAddress = defineAsyncComponent(() =>
  //import("@/modules/module-2-main-pages/components/editprofile/AddPersonalAddress.vue")
  import("./../../module-2-main-pages/components/editprofile/AddPersonalAddress.vue")
);

export default {
  mixins: [OptionShowMixin, settings],
  components: {
    OptionStoreAddress,
    OptionHomeDeliveryAddress,
    ButtonOptions,
    ButtonOrange,
    ChosenOptionDialog,
    AddPersonalAddress,
  },
  data() {
    return {
      radioChosen: true,
      dialogPickUpStoreOptions: false,
      dialogHomeDeliveryOptions: false,
      optionChosenStore: false,
      optionChosenHomeDelivery: false,
      buttonBlueExchange: true,
      buttonOrangeExchange: false,
      titlePickUpStore: false,
      titleHomeDelivery: false,
      chosenOption: "",
      editOption: true,
      shipping_address_aux: "",
      OrderDone: false,
      pickUpStore: true,
      homeDelivery: true,
      loading: false,
      loadingOrder: false,
      alertPleaseChooseOption: false,
      alertPleaseChooseAddress: false,
      orderFail: false,
      // address options
      addressOptions: [],
      addressOptionsType: "shipping",
      chosenShippingAddress: null,
      chosenBillingAddress: null,
      sameAddressForInvoice: true,
      addAddressDialog: false,
      // Order note
      addNote: false,
      NoteInput: "",
      // Product attribute options
      productAttributes : []
    };
  },
  props: {
    points: {
      type: Object,
    },
    product: {
      id: {
        type: Number,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
      stock: {
        type: Number,
        required: true,
      },
      brand: {
        type: String,
        required: true,
      },
      category_id: {
        type: Number,
        required: true,
      },
      sku: {
        type: Number,
        required: true,
      },
    },
  },
  computed: {
    ...mapState("auth", ["user"]),

    addresses() {
      return this.$store.state.addresses.addresses.data;
    },

    shippingAddresses() {
      return this.addresses?.filter((address) => address.type === 'shipping')
    },

    billingAddresses() {
      return this.addresses?.filter((address) => address.type === 'invoice')
    },

    // points() {
    //   return this.$store.state.points.points;
    // },
  },
  methods: {
    //...mapActions("points", ["getPoints"]),

    ...mapActions("addresses", ["getAddresses"]),

    formatAddress,
  },
  watch: {
    chosenShippingAddress: function (val) {
      if (this.sameAddressForInvoice) {
        this.chosenBillingAddress = val;
      }
    },
    sameAddressForInvoice: function (val) {
      if (val === true) {
        this.chosenBillingAddress = this.chosenShippingAddress;
      }
    },
    chosenBillingAddress: function (val) {
      if (this.product.type === 1) {
        this.chosenShippingAddress = val;
      }
    },
    // Update addresses when the create address dialogue is closed
    addAddressDialog: function (val) {
      if (val === true) {
        this.dialogHomeDeliveryOptions=false;
      }

      if (val === false) {
        this.dialogHomeDeliveryOptions=true;
        this.getAddresses()
        .then(() => {
          if (this.addressOptionsType == 'shipping') {
            this.addressOptions = this.shippingAddresses;
          }

          if (this.addressOptionsType == 'invoice') {
            this.addressOptions = this.billingAddresses;
          }
        })
        
      }
    },
  },

  mounted() {
    this.shipping_address_aux = this.user.shipping_address;
    // this.getPoints();
    this.setHomeDelivery();
    this.getAddresses();
    this.getProductOptions();
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 600px) {
  .container-button-like {
    position: relative;
  }
}

.q-spinner {
  color: v-bind("settings.color_primary");
}

.button-ok {
  color: v-bind("settings.color_primary");
  border-color: v-bind("settings.color_primary");
}

.container-confirmation {
  background: #f2f2f2;
  border-radius: 25px 25px 25px 0px;
}

.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
}
/*.note-input {
  height: 100px !important;
}*/
</style>
