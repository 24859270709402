import { getCountryNameFromCode } from "@/services/helpers/CountryList";

const formatAddress = (address, postalCode, city, province, country) => {
    let fullAddress = "";

    if (address) {
        fullAddress += address + ", ";
    }
    if (postalCode) {
        fullAddress += postalCode + ", ";
    }
    if (city) {
        fullAddress += city + ", ";
    }
    if (province) {
        fullAddress += province + ", ";
    }
    if (country) {
        fullAddress += (getCountryNameFromCode(country) ?? country);
    }

    /*if (address== null || postalCode== null || city== null || province== null || country== null) {
        return null
    }*/

    //return address + ", " + postalCode + ", " + city + ", " + province + ", " + (getCountryNameFromCode(country) ?? country);
    return fullAddress;
}


export { formatAddress };